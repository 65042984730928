import {
  funcScrollControl,
  funcLoad,
  topMain,
} from './index';

const funcEntry = (() => {



  funcScrollControl.noScroll();

  const pageTop = document.getElementById('pageTop');

  if (pageTop) {

    document.addEventListener("DOMContentLoaded", () => {
      funcLoad()
      topMain()
    });

  } else {

    document.addEventListener("DOMContentLoaded", () => {
      funcLoad()
    });

  }



})();
export default funcEntry;