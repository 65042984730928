const funcNav = (() => {

  let sW = window.innerWidth;

  let nav = document.getElementById('jsNav');
  let navBtn

  if (sW > 750) {
    navBtn = document.getElementById('jsNavBtn');
  } else {
    navBtn = document.getElementById('jsNavBtnSp');
  }

  navBtn.addEventListener('click', (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (navBtn.classList.contains('act') == true) {
      nav.classList.remove('act');
      navBtn.classList.remove('act');
      nav.classList.add('no-act');
    } else {
      nav.classList.add('act');
      navBtn.classList.add('act');
      nav.classList.remove('no-act');
    }
  });

})();
export default funcNav;